@import "/node_modules/animate.css/animate.css";
@import "/src/constants";

html {
  cursor: url("/src/assets/images/cursor.png"), auto;
}

a {
  cursor: url("/src/assets/images/cursor-pointer.png"), auto;
}

body {
  background-color: black;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.95),
      rgba(0, 0, 0, 0.95)
    ),
    url("/src/assets/images/ape-tile-bg-7.webp?as=webp&width=512");
  background-size: 512px;

  &.loading {
    overflow: hidden;
  }

  > main {
    position: relative;
    overflow-x: hidden;
  }
}

h1.section-title {
  font-size: $section-title-font-size;
  text-align: center;

  @media (max-width: 900px) {
    font-size: $section-title-font-size-tablet;
  }

  @media (max-width: 600px) {
    font-size: $section-title-font-size-mobile;
  }
}

@media (max-width: 580px) {
  body > main {
    section {
      padding: 0 16px;
    }
  }
}
